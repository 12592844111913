import React from 'react';
import LoadingSpinner from '../animations/LoadingSpinner';

export default function Button({
    label,
    className,
    buttonClasses = '',
    onClickHandler,
    type,
    id,
    isSecondaryButton,
    isLoading = false,
    minWidth,
}) {
    const fontSize = 'text-text-base md:text-base lg:text-lg';
    const textProperties = `${!isSecondaryButton ? 'text-white' : 'text-purple-700'} text-center`;
    const animationProperties = 'transition-all duration-200 ease-linear';
    const padding = 'py-[12px] px-6 md:px-8 lg:px-10 xl:px-8';
    const borderRadius = 'rounded-[72.5px]';
    const border = 'border border-primary';
    const backgroundGradient = `${!isSecondaryButton ? 'bg-gradient-to-r from-primary to-purple-700' : 'bg-white'} `;
    const displayProperties = 'relative group';

    const attributes = {};
    if (id) attributes.id = id;
    type ? (attributes.type = type) : (attributes.type = 'submit');

    return (
        <div
            className={`${backgroundGradient} inline-block shadow-md hover:shadow-sm ${
                minWidth ? `min-w-[${minWidth}px]` : ''
            } gap-5 ${textProperties} ${padding} ${animationProperties} ${displayProperties} ${borderRadius} ${border} ${fontSize} ${className}`}
        >
            {isLoading && (
                <div className="absolute top-0 left-[10%] flex items-center justify-center h-full">
                    <LoadingSpinner />
                </div>
            )}
            <button
                {...attributes}
                onClick={onClickHandler}
                className={`absolute top-0 left-0 h-full w-full hover:opacity-30 ${borderRadius} ${animationProperties} ${buttonClasses}`}
                aria-label={label || null} // Add aria-label
                aria-busy={isLoading ? 'true' : null} // Add aria-busy
                aria-disabled={isLoading ? 'true' : null} // Add aria-disabled
                disabled={isLoading} // Disable button when isLoading is true
            ></button>
            <span
                className={`relative z-10 pointer-events-none transition ease-linear ${
                    isLoading ? 'ml-4' : ''
                }`}
            >
                {!isLoading ? label : 'Processing...'}
            </span>
        </div>
    );
}
