import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { APPLE_STORE_LINK } from '../../../utils/helpers/download-link-urls';

export default function DownloadAppCTA({ containerStyles = '', id = '' }) {
  const { appleStoreImg } = useStaticQuery(graphql`
    query HomePageHeroQuery {
      appleStoreImg: file(name: { eq: "apple-store" }) {
        childImageSharp {
          gatsbyImageData(width: 296, quality: 100)
        }
      }
    }
  `);

  return (
    <div className={`${containerStyles} flex h-[90px]`}>
      <a
        href={APPLE_STORE_LINK}
        target="_blank"
        id={`${id}-apple-store-download-link`}
      >
        <GatsbyImage
          image={getImage(appleStoreImg)}
          alt="apple store download button"
          imgStyle={{ objectFit: 'contain' }}
          className="h-[90px]"
        />
      </a>
    </div>
  );
}
