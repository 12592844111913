import React, { forwardRef } from 'react';
import ToolTip from '../utility/ToolTip';

function InputField(
  {
    id,
    type = 'text',
    placeholder = '',
    label = '',
    value,
    onChange = () => {
    },
    required = false,
    hasIcon = false,
    error = {},
    min,
    tooltip = '',
    styles = ''
  },
  ref
) {
  const inputLabel = required ? `${label}*` : label;
  const errorMessageClassNames = `text-xs text-secondary-red ${
    error.message ? 'visible' : 'invisible'
  }`;

  const defaultStyles = `block w-full px-4 py-3 h-12 appearance-none rounded-3xl border-0
    text-base bg-grey-gradient text-grey-25 placeholder-grey-25 focus:outline-none focus:ring-1 focus:ring-highlight shadow-md
              ${hasIcon ? 'pl-8' : ''} ${
    error.message ? 'border-secondary-red' : ''
  }`;

  return (
    <div className='relative w-full'>
      {tooltip.length > 0 && <ToolTip message={tooltip} />}
      {label && (
        <label
          className='text-sm font-medium text-gray-700 mb-1'
          htmlFor={id}
        >
          {inputLabel}
        </label>
      )}
      <input
        id={id}
        name={id}
        ref={ref}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        min={min}
        className={styles ? styles : defaultStyles}
      />
      <label className={errorMessageClassNames}>{error.message}</label>
    </div>
  );
}

export default forwardRef(InputField);
