import React, { useState } from 'react';
import { Link } from 'gatsby';
import Button from '../atoms/inputs/Button';
import { useLocation } from '@reach/router';

export default function JoinTheWaitlist() {
  const { pathname } = useLocation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const inputStyles = 'block w-full px-4 py-3 h-12 appearance-none rounded-3xl border-0 text-base bg-grey-gradient text-grey-25 placeholder-grey-25 focus:outline-none focus:ring-1 focus:ring-highlight shadow-md';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!name || !email || !city) {
      setIsLoading(false);
      setErrorMessage('Please fill in all fields: Name, Email, and City.');
      return;
    }

    try {
      const response = await fetch('/.netlify/functions/community-waitlist', {
        method: 'POST',
        body: JSON.stringify({ name, email, city }),
      });

      setIsLoading(false);
      if (response.ok) {
        setName('');
        setEmail('');
        setCity('');
        setErrorMessage('Thank you for joining the waitlist!');
      } else if (response.status >= 400 && response.status < 500) {
        setErrorMessage('Please check your input.');
      } else {
        setErrorMessage('An error occurred, please try again.');
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div id="join-waitlist-sign-up-form" className="relative z-10 text-center w-full bg-white px-0 lg:px-10 py-4 xl:py-8 rounded-3xl pt-20">
      <div className="max-w-3xl text-center mx-auto mb-8 lg:mb-8">
        <h3 className="text-3xl md:text-4xl lg:text-5xl text-light-grey font-light lg:leading-[62.5px] mb-4">
          <span className="text-highlight">Join Our Waitlist</span>
        </h3>
        <p className="text-lg lg:text-xl xl:text-2xl max-w-lg mx-auto">
          Sign up now to get early access and stay informed!
        </p>
      </div>
      <form onSubmit={handleSubmit} className="mx-auto max-w-lg">
        <div className="flex flex-col items-start justify-start w-full">
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required className={inputStyles + ' mb-4'} placeholder="Name" />
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required className={inputStyles + ' mb-4'} placeholder="Email" />
          <input type="text" value={city} onChange={(e) => setCity(e.target.value)} required className={inputStyles + ' mb-4'} placeholder="City" />
        </div>
        <p className={`my-3 text-xs lg:text-base sm:mt-3 text-grey-25 font-light max-w-xs md:max-w-[unset] mx-auto`}>
          By signing up, you agree to our <Link to="/legal/terms" className="text-highlight">terms of service</Link>.
        </p>
        <div className="mx-auto flex items-center justify-center h-full relative">
          <Button type="submit" label="Join Waitlist" className="min-w-[200px]" isLoading={isLoading} minWidth={213} id="join-waitlist-button" />
        </div>
        {errorMessage && (
          <p className="text-center my-4 lg:my-6 text-default text-xl lg:text-2xl">{errorMessage}</p>
        )}
      </form>
    </div>
  );
}
