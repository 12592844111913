import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';

deckDeckGoHighlightElement();

const components = {
  pre: (props) => <deckgo-highlight-code {...props} />,
};

export const wrapMDX = ({ children }) => <MDXProvider components={components}>{children}</MDXProvider>;
