import React from 'react';
import TwitterIcon from '../../atoms/icons/TwitterIcon';
import LinkedInIcon from '../icons/LinkedInIcon';
import { isBrowser } from '../../../utils/helpers/environment';

export default function SocialSharing({ post }) {
  const originalRef = isBrowser()
    ? window.location.origin
    : 'https://flockx.io';
  const text = `${post.frontmatter.title}`;
  const blogUrl = `${originalRef}${post.frontmatter.slug}`;
  const hashtags = post.frontmatter.tags
    ? post.frontmatter.tags.toString()
    : '';
  const twitterUrl = `https://twitter.com/intent/tweet?original_referer=${originalRef}&text=${text}&url=${blogUrl}&hashtags=${hashtags}`;
  const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${blogUrl}`;
  return (
    <div className='flex gap-4 md:gap-8'>
      <a
        href={twitterUrl}
        className='twitter-share-button'
        data-show-count='false'
        target='_blank'
      >
        <TwitterIcon />
      </a>
      <a href={linkedInUrl} target='_blank'>
        <LinkedInIcon />
      </a>
      <script
        async
        src='https://platform.twitter.com/widgets.js'
        charSet='utf-8'
      ></script>
    </div>
  );
}
