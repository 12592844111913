import React, { useState } from 'react';
import Fade from '../animations/Fade';
import useMediaQuery from '../../../utils/hooks/useMediaQuery';
import useHover from '../../../utils/hooks/useHover';

export default function ToolTip({ message }) {
  const [clicked, setClicked] = useState(false);
  const [hoverRef, isHovered] = useHover();
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  let timeout;

  const handleClick = () => {
    clearTimeout(timeout);
    setClicked(true);
    timeout = setTimeout(() => setClicked(false), 5000);
  };

  return (
    <div>
      <div
        className={`has-tooltip cursor-pointer ml-3 relative top-[25%] bg-transparent border border-grey-25 text-grey-25 rounded-full w-4 h-4 flex justify-center align-bottom whitespace-nowrap`}
        onClick={handleClick}
        ref={hoverRef}
      >
                <span className='relative text-xs pointer-events-none top-[-1px]'>
                    ?
                </span>
      </div>
      <Fade toggle={clicked || isHovered}>
        <div>
                    <span
                      className={`
                            ${clicked ? 'visible z-50' : ''} 
                            ${isHovered ? 'visible z-50' : ''}
                            ${
                        !isDesktop
                          ? 'fixed inset-x-0 mx-auto bottom-[110%]'
                          : 'bottom-[110%] right-[9%]'
                      } 
                            absolute border-solid border border-highlight 
                            rounded shadow-lg bg-grey text-white
                            max-w-[300px] md:max-w-sm p-3`}
                    >
                        {message}
                    </span>
        </div>
      </Fade>
    </div>
  );
}
